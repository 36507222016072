import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
// import promoListComponent from '@/views/raffle/promoList/promoListComponent.vue'
// import promoParticipantsComponent from '@/views/raffle/promoParticipants/promoParticipantsComponent.vue'
// import promoSummaryComponent from '@/views/raffle/promoSummary/promoSummaryComponent.vue'
// import areasComponent from '@/views/raffle/areas/areasComponent.vue'
// import raffleDrawComponent from '@/views/raffle/raffleDraw/raffleDrawComponent.vue'
// import moduleSettingsComponent from '@/views/raffle/settings/moduleSettingsComponent.vue'
import repository from "@/api/credentials/credentials";
import store from '@/store';


Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'login',
		component: LoginView,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/',
		name: 'home',
		component: HomeView,
		beforeEnter: (to, from, next) => {
            repository
                .authentication()
                .then(() => {
					store.dispatch("credentials");
					store.dispatch("renderSideNav");

					let dropdown_urls = [
						{
							url: 'promoListDropDown',
							state_name: 'promo_list'
						},
						{
							url: 'getAreaDropdown',
							state_name: 'area_list'
						},
						{
							url: 'getStoreBranchDropdown',
							state_name: 'store_branch_list'
						},
						{
							url: 'getProvinces',
							state_name: 'provinces_list'
						},
						{
							url: 'getAllSideBar',
							state_name: 'side_bar'
						},
					];
					dropdown_urls.forEach(async el=>{
						await store.dispatch('urlGet', el).then(response=>{
							el.state_data = response;
							store.commit('MUTATE_DROPDOWNS', el)
							if(el.state_name == 'promo_list'){
								let findActive = response.find(x => x.active == 1)?.value ?? 0
								store.commit('ACTIVE_PROMO', findActive);
							}
						})
					})
                    next();
                })
                .catch(() => {
                    localStorage.removeItem("token");
                    return next({ name: "login" });
                });
        },
		children:[
			{
				path: '/promo_participants',
				name: 'promo_participants',
				// component: promoParticipantsComponent
				component: () => import('@/views/raffle/promoParticipants/promoParticipantsComponent.vue'),
			},
			{
				path: '/promo_summary',
				name: 'promo_summary',
				// component: promoSummaryComponent
				component: () => import('@/views/raffle/promoSummary/promoSummaryComponent.vue'),
			},
			{
				path: '/promo_list',
				name: 'promo_list',
				// component: promoListComponent
				component: () => import('@/views/raffle/promoList/promoListComponent.vue'),
			},
			{
				path: '/areas',
				name: 'areas',
				// component: areasComponent
				component: () => import('@/views/raffle/areas/areasComponent.vue'),
			},
			{
				path: '/raffle_draw',
				name: 'raffle_draw',
				// component: raffleDrawComponent
				component: () => import('@/views/raffle/raffleDraw/raffleDrawComponent.vue'),
			},
			{
				path: '/module_settings',
				name: 'module_settings',
				// component: moduleSettingsComponent
				component: () => import('@/views/raffle/settings/moduleSettingsComponent.vue'),

			},
			{
				path: '/changepassword/:id',
				name: 'change-password',
				component: () => import('@/views/utils/ChangePasswordComponent.vue'),
				meta: {
					requiresAuth: true,
					title: 'Change Password',
				}
			},
			{
				path: '/privacypolicy',
				name: 'privacy_policy',
				component: () => import('@/views/raffle/privacySettings/PrivacyPolicyTableComponent.vue'),
				meta: {
					requiresAuth: true,
					title: 'Privacy Policy',
				}
			}
		]
	},
	{
		path: '/about',
		name: 'about',
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
