<template>
	<v-navigation-drawer
		:width="navigation.width"
		:mini-variant.sync="drawers"
		class="bg-ingco"
		app
		permanent
		ref="drawer"
	>
		<v-toolbar
			height="41"
			color="#f69f1a"
			prominent
			dense
			collapse
			elevation="0"
		>
		<template #img>
			<v-img
			  src="@/assets/ingcoph_bg/ingcoph_logo_alt_new.png"
			  contain
			></v-img>
		</template>
		</v-toolbar>
		<v-list>
			<v-list-item-group 
				v-model="current_nav"
				active-class="border"
			>
				<v-list-item
					v-for="(item, i) in side_nav"
          			:key="i"
					:to="{ name: item.route_name }"
					link
					class="text-decoration-none"
					dense
				>
					<v-list-item-icon>
						<v-icon v-text="item.icon"></v-icon>
					</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="text-caption text-wrap" v-text="item.menubar_desc"> </v-list-item-title>
				</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
  	</v-navigation-drawer>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  props: ["drawers"],
  data() {
		return {
			group: true,
			navigation: {
				shown: false,
				width: 200,
				borderSize: 4,
			},
			current_nav: 1,
			side_nav:[]
		};
	},
	computed: {
		...mapGetters([
			"USERACCOUNT_LOAD",
			"GET_SIDE_MENU_BARS",
		]),
	},
	methods: {
		active_bg(id) {
			if (this.LINK_ACTIVE == id) {
				return "text-decoration-none v-list-item--active theme--light";
			} else {
				this.$store.commit("LINK_ACTIVE", this.$router.app._route.params.id);
				return "";
			}
		},
		route_link(child) {
			let a = document.createElement("a");
			let link = this.$router.resolve({
				name: child.route_name,
				params: { id: child.link_id },
			});
			location.href = window.location.origin + "/" + link.href;
		},
		setBorderWidth() {
			let i = this.$refs.drawer.$el.querySelector(
				".v-navigation-drawer__border"
			);
			i.style.width = this.navigation.borderSize + "px";
			i.style.cursor = "ew-resize";
			i.style.backgroundColor = "#F69F1A";
		},
		setEvents() {
			const minSize = this.navigation.borderSize;
			const el = this.$refs.drawer.$el;
			const drawerBorder = el.querySelector(".v-navigation-drawer__border");
			const vm = this;
			const direction = el.classList.contains("v-navigation-drawer--right")
				? "right"
				: "left";

			function resize(e) {
				document.body.style.cursor = "ew-resize";
				let f =
				direction === "right"
					? document.body.scrollWidth - e.clientX
					: e.clientX;
				el.style.width = f + "px";
			}

			drawerBorder.addEventListener(
				"mousedown",
				(e) => {
				if (e.offsetX < minSize) {
					el.style.transition = "initial";
					document.addEventListener("mousemove", resize, false);
				}
				},
				false
			);

			document.addEventListener(
				"mouseup",
				() => {
				el.style.transition = "";
				this.navigation.width = el.style.width;
				document.body.style.cursor = "";
				document.removeEventListener("mousemove", resize, false);
				},
				false
			);
		},
	},
	mounted() {
		// this.active_bg("");
		// this.setBorderWidth();
		// this.setEvents();
	},
	watch: {
		GET_SUBMIT_SUCCESS: {
			handler(val) {
				this.$store.commit("ROLE_ACCESS_LOAD", "");
				this.$store.commit("DIALOG", false);
				this.$store.dispatch("credentials");
				Swal.fire({
				title: "Control has been saved",
				icon: "success",
				timer: 1500,
				showConfirmButton: false,
				});
				this.$store.commit("SUBMIT_SUCCESS", false);
			},
		},
		NEW_DEPARTMENT: {
			handler(val) {
				this.$store.dispatch("credentials");
				// this.$store.dispatch("getAllDepartment");
				// this.$store.commit("NEW_DEPARTMENT", false);
			},
		},
		group() {
			this.drawers = false;
		},
		drawers(val) {
			if (!val) {
				this.navigation.width = 200;
			}
		},
		GET_SIDE_MENU_BARS:{
			handler(val){
				this.side_nav = val;
			}
		}
	},
};
</script>
<style scoped>
.v-application .primary--text {
  color: #000001 !important;
  caret-color: #000000 !important;
}

.active_link {
  background-color: #1e1e1c !important;
  color: white;
}

.active_text {
  color: #f49e17 !important;
}

.link_color {
  color: #000001;
}
</style>
