<template>
	<v-app>
		<navbar></navbar>
		<router-view></router-view>
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HelloWorld from "../components/HelloWorld";
import navbar from '@/views/system/navbar.vue';


export default {
	name: "Home",
	computed: {
		...mapGetters(['USERACCOUNT_LOAD'])
	},
	components: {
		HelloWorld,
		navbar
	},
};
</script>
