<template>
  	<v-app>
		<v-main>
			<router-view/>
		</v-main>
  	</v-app>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  	name: 'App',
	data: () => ({
	}),
	computed:{
		...mapGetters([
			'USERACCOUNT_LOAD'
		])
	},
	created(){
	}
};
</script>

<style src="./assets/css/custom.css"></style>

<style>
	.swal2-actions {
		font-size: 0.84rem !important;
		color: white !important;
	}
</style>
