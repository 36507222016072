import api from '@/api/api'

export default {
  useraccount() {
    return api.get('api/user')
  },
  register(params) {
    return api.post('api/register', params);
  },
  login(params) {
    return api.post('api/login', params);
  },
  logout() {
    return api.post('api/logout')
  },
  authentication() {
    return api.get('api/authenticated')
  },
  checkAccessUser(params) {
    return api.get('api/checkAccessUser/' + params);
  },
};
