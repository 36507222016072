<template>
    <v-card>
        <v-app-bar dark class="bg-ingco" height="50" elevation="4">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
            <router-link :to="{name:'home'}"  class="text-decoration-none text-caption">Home</router-link>
            <v-row class="mt-4 ml-2">
                <v-col cols="6">
                    <v-autocomplete
                        v-model="filter.promo_id"
                        :items="GET_ALL_DROPDOWN.promo_list"
                        label="Promo Selection"
                        auto-select-first
                        clearable
                        outlined
                        dense
                        @change="changePromo()"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-app-bar-title class="pa-0 ma-0">
                <v-menu offset-y :close-on-click="closeOnClick" :close-on-content-click="closeOnClick">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="pa-0 ma-0 py-1">
                            <v-avatar size="34" color="gold" class="pa-0 ma-2">
                                <v-img src="@/assets/ingcoph_bg/ingcoph_logo_alt.jpg">
                                </v-img>
                            </v-avatar>
                            <span class="text-caption"> {{ USERACCOUNT_LOAD?.name || '' }} </span>
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>
                    <v-list dense>
                        <v-list-item link>
                            <v-icon size="16">mdi-account</v-icon>
                            <v-list-item-title> My Profile </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="changePassword">
                            <v-icon size="16">mdi-lock</v-icon>
                            <v-list-item-title> Change Password </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="logout">
                            <v-icon size="16">mdi-logout</v-icon>
                            <v-list-item-title> Logout </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar-title>
        </v-app-bar>
        <sidenav :drawers="drawer"></sidenav>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import sidenav from "./sidenav.vue";
export default {
    data() {
        return {
            drawer: false,
            group: false,
            closeOnClick: false,
            filter:{
                promo_id: null
            }
        };
    },
    components: { sidenav },
    computed: {
        ...mapGetters(["USERACCOUNT_LOAD", "GET_ALL_DROPDOWN", "GET_ACTIVE_PROMO"]),
    },
    methods: {
        logout() {
            localStorage.setItem("last_visit", window.location.pathname);
            let payload = {
                url: 'logout'
            }
            this.$store.dispatch("urlPost", payload).then(() => {
                this.$router.push({ name: "login" });
            });
        },
        changePassword(){
            this.$router.push({
                name:'change-password'
            })
        },
        changePromo(){
            this.$store.commit('ACTIVE_PROMO', this.filter.promo_id);
        }
    },
    watch:{
        "GET_ACTIVE_PROMO":{
            handler(val){
                if(val){
                    this.filter.promo_id = val
                }
            },
            immediate: true
        }
    }
};
</script>
<style scoped>
a {
    color: rgb(60, 54, 54) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-ingco{
    background-color: #F69F1A !important;
    color: #1e1e1c !important;
}
</style>
