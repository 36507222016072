import Vue from "vue";
import Vuex from "vuex";
import Raffle from './raffle/raffle'
Vue.use(Vuex)

export default new Vuex.Store({
	strict: false,
	modules: {
		Raffle,
	},
});

