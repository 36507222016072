import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import dayjs from './plugins/dayjs'
import CKEditor from '@ckeditor/ckeditor5-vue2';
window.$ = window.jQuery = require('jquery');


Vue.use(CKEditor);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use({
  install: function (Vue) {
    Vue.prototype.$dayjs = dayjs
  }
})