<template>
  <v-app>
    <v-container bg fill-height grid-list-md text-xs-center>
		<v-layout row wrap align-center>
			<v-flex>
				<v-card
					class="mx-auto"
					max-width="374"
					elevation="0"
					bg
					fill-height
					grid-list-md
					text-xs-center
				>
					<v-toolbar elevation="0" class="ma-0 pa-0" height="140px">
						<template #img>
							<v-img
							src="@/assets/ingcoph_bg/ingcoph_logo_alt_new.png"
							contain
							></v-img>
						</template>
					</v-toolbar>
					<v-card-text>
						<v-form
							@submit="loginSubmit"
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<v-text-field
								v-model="form.username"
								:rules="usernamerules"
								:append-icon="error != '' ? 'mdi-account-alert' : ''"
								label="Username"
								type="text"
								class="py-2"
								hide-details="auto"
								required
								outlined
								dense
								@keypress="error = ''"
							></v-text-field>
							<v-text-field
								v-model="form.password"
								:rules="passwordrules"
								type="password"
								label="Password"
								hide-details="auto"
								requireds
								outlined
								dense
								@keypress="error = ''"
							></v-text-field>
							<v-card-actions class="py-5 justify-center">
								<v-row>
									<v-col cols="12" sm="12"  class="text-center">
										<span class="text-danger">{{ error }}</span>
									</v-col>
									<v-col cols="12" sm="12"  class="text-center">
										<v-spacer />
										<div class="btn-toolbar">
											<v-btn :loading="loading" type="submit" elevation="2">
											Login
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
		valid: true,
		loading: false,
		form: {
			username: null,
			password: null,
			remember: null,
		},
		error: "",
		passwordrules: [(v) => !!v || "Password is required."],
		usernamerules: [(v) => !!v || "Username is required."],
		logged_time: 0,
		};
	},
	computed: {
		...mapGetters(["credentials"]),
	},
	methods: {
		loginSubmit(e) {
			e.preventDefault();
			this.$refs.form.validate();
			this.loading = true;
			if (this.form.username == null && this.form.password == null) {
				this.loading = false;
			} else {
				let payload = {
				url: "login",
				...this.form,
				};
				this.$store.dispatch("urlPost", payload).then((success) => {
					if (success.status == 400) {
						this.loading = false;
						this.error = success.message;
					} else {
						let last_visit = localStorage.getItem("last_visit");
						// this.$store.dispatch("join_notification");
						localStorage.setItem("token", success.access_token);
						localStorage.setItem("logged_times", this.logged_time);
						localStorage.setItem("last_logged_date", this.current_date);
						if (localStorage.getItem("last_visit")) {
								this.$router.push({ path: last_visit }).catch((err) => {
								this.loading = false;
							});
						} else {
							this.$router.push({ path: "/" }).catch((err) => {
								this.loading = false;
							});
						}
					}
				}).catch((error) => {
					console.log(error);
					this.error = error.response.data.message;
					this.loading = false;
				});
			}
		},
	},
	mounted() {
	},
};
</script>
<!-- <style src="../../assets/css/custom.css"></style> -->
<!-- <style src="../../assets/css/fontawesome-free/css/all.min.css"></style>
  <style src="../../assets/css/vuetify/materialdesignicons.min.css"></style>
  <style src="../../assets/css/vuetify/vuetify.min.css"></style>
  <style src="../../assets/css/adminlte.min.css"></style> -->
